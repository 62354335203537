.background {
  position: absolute;
  top: 0;
  z-index: -500;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(180deg, #290730 0%, #0a0011 87.5%), */
  /* #0a0011; */
  /* background: linear-gradient(180deg, #5f0039 0%, #0a0011 87.5%), #0a0011; */
}

.container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.google-login-container {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
